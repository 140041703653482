export const timestampToDisplayString = (timestamp) => {
    var time = new Date(timestamp * 1000).toLocaleTimeString("pl-PL");
    var date = new Date(timestamp * 1000).toLocaleDateString("pl-PL");
    return time + " " + date;
}

export const objectIdToDisplayString = (objectId) => {
    var timeStamp = parseInt(objectId.slice(0, 8), 16) + 
    Math.floor(parseInt(objectId.slice(-6), 16) / 16777.217);
    return timestampToDisplayString(timeStamp);
}