import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import { MainHeading } from "components/misc/Headings";
import { getBlogEntry } from "api/ClientServiceApi";
import { useParams } from "react-router-dom";
import { objectIdToDisplayString } from "util/DateUtil";

const HeadingRow = tw.div`flex`;
const Heading = tw(MainHeading)`text-gray-900`;
const PostContainer = tw.div`mt-10 w-full sm:pr-8`;
const Post = tw.div`flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Description = tw.div`
    [> *]:(pb-5)
    [> h2]:(text-3xl sm:text-3xl font-black tracking-wide text-gray-800 py-2)
    [> h3]:(text-2xl sm:text-2xl font-black tracking-wide text-gray-800 py-2)
    [> h4]:(text-xl sm:text-xl font-black tracking-wide text-gray-800 py-2)
    [> p]:(mb-4 text-lg font-normal text-gray-700)
    [> ol]:(space-y-1 text-gray-700 list-decimal list-inside)
    [> ul]:( space-y-1 text-gray-700 list-disc list-inside)
    [> button]:(px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-16 mx-auto flex justify-center)
    [a]:(underline text-blue-600 hover:text-blue-800 visited:text-purple-600)
    [.btn]:(px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-16)
    [.btn-primary]:(px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-16 mx-auto flex justify-center)
`;

export default () => {
    const { entry } = useParams()
    const [entryData, setEntry] = useState();

    useEffect(() => {
        async function fetchPost() {
            var response = await getBlogEntry({ entry });
            setEntry(response);
        }

        fetchPost();
    }, [entry]);

    return (
        <AnimationRevealPage>
            <Header />
            <Container>
                <ContentWithPaddingXl>
                    <HeadingRow>
                        <Heading>{entryData ? entryData.title : null}</Heading>
                    </HeadingRow>
                    {entryData ? <PostContainer key={1} >
                        <Post className="group">
                            <Image imageSrc={entryData.image} />
                            <Info>
                                <Category>{entryData.tags ? entryData.tags.join(", ") : null}</Category>
                                <CreationDate>{objectIdToDisplayString(entryData.id)}</CreationDate>
                                <Description dangerouslySetInnerHTML={{ __html: entryData.content }} />
                            </Info>
                        </Post>
                    </PostContainer> :
                        null}
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
