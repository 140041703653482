import axios from "axios";

const deviceModelsUrl = `${window.config.apiUrl}/device-models?language=pl_PL`;
const blogEntriesUrl = `${window.config.apiUrl}/blog/v1/entries`;

export const getDeviceModels = () => {
    return axios.get(deviceModelsUrl)
        .then(response => response.data);
};

export const getDeviceMakes = () => {

};

/**
 * @param {Object} params
 * @param {string} params.entry Entry ID or URL alias
 */
export const getBlogEntry = async ({entry}) => {
    return await axios.get(`${blogEntriesUrl}/${entry}`)
        .then(response => response.data);
}

/**
 * @param {Object} params
 * @param {Number} params.count How many entries to read. Accepts values from 1 to 10
 * @param {Number} params.offset Index for which entries to start reading from. e.g. 2 would return 3-x newest entries, where x is set by `count` argument
 */
export const getBlogEntries = async ({count = 10, offset = 0}) => {
    return await axios.get(blogEntriesUrl, {
 
        params: {count, offset}
    })
        .then(response => response.data);
}